import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class Header extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: ''
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }
    render(){

        return(
            <header className="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow">
  <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#"><img src="logo-light.png" style={{ width: 150 }} /></a>
  <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <ul className="navbar-nav px-3">
    <li className="nav-item text-nowrap">
      <Link to="/login" className="text-white">Salir</Link>
    </li>
  </ul>
</header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Header);