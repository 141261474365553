import React, { Component} from 'react'
import { Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

class Taza extends Component {
    constructor(){
        super()
        this.state = {
            user: JSON.parse(localStorage.getItem('dym_user')),
            tazas: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeTaza = this.handleChangeTaza.bind(this)
    }
    
    handleChangeTaza(e){
        const { name, value } = e.target
        const { tazas } = this.state
        const id = e.target.getAttribute('idtaza')
        const i = tazas.findIndex(ta => ta._id === id)
        if(i > -1){
            tazas[i].ammount = parseFloat(value)
            return this.setState({ tazas })
        }
        return false
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    componentDidMount(){
        const { user } = this.state
        this.getTazas()
    }

    getTazas(){
      this.setState({ loadingTaza: true })
      return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/sistema_getTazas`)
      .then(res => res.json())
      .then(res => {
          const tazas = JSON.parse(res)
          return this.setState({ tazas, loadingTaza: false })
      })
      .catch(error => {
          toast.error('Error al consultar la información')
          this.setState({ loadingTaza: false })
          return false
      })
  }

  guardarCambios(){
    const { tazas } = this.state

    if(tazas.length < 1) return toast.error('No hay tazas que editar')

    this.setState({ loadingTaza: true })
      return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/sistema_modificarTazas',{
          method: 'POST',
          body: JSON.stringify(tazas),
          headers:{
            'Content-Type': 'application/json'
          }
      })
      .then(res => res.json())
      .then(async res => {
          
          toast.success('Cambios guardados exitosamente')
          return this.setState({ loadingTaza: false })
      })
      .catch(error => {
          console.log(error)
          this.setState({ loadingTaza: false })
          return toast.error('No se pudo subir la imagen')
      })


  }

    misDatos(){
      const { user, reglasbanco, tazas, loadingTaza, transferencias } = this.state

      if(loadingTaza) return <div><Spinner animation="border" /><h5>Cargando datos...</h5></div>

      return <Row className="bienvenida">

      
        <Col xs={12} className="mt-3">
          { tazas.length > 0 ? <button className="btn btn-success mb-3" onClick={()=>this.guardarCambios()} >GUARDAR CAMBIOS</button> : false}
        
           {
               tazas.map((taza,ita) => {
                   return <Card key={`taza-${ita}`} className="mb-3">

                        <Card.Body>
                        <Row>
                            <Col md={12}>
                                <h4>{taza.from} a { taza.to}</h4>
                                <input type="number" className="form-control" idtaza={taza._id} value={taza.ammount} onChange={this.handleChangeTaza} />
                            </Col>
                       
                       </Row>
                        </Card.Body>

                       </Card>
               })
           }
        
        </Col>

      </Row>

    }

    
    modificarEstado(estado,id){
      const { transferencias } = this.state
      const i = transferencias.findIndex(tra => tra._id === id)
      if(estado === "2"){
        if(i > -1){
          if( !transferencias[i].imagenlista ) return toast.error('Primero debes subir una imagen')
        }
      }


      this.setState({ loadingAcciones: true })
      return fetch(`https://aquicambias.com/server/dymsolutionsserver/public/api/marcar?id=${id}&status=${estado}`)
      .then(res => res.json())
      .then(res => {
        if(i > -1){
          transferencias[i].status = estado.toString()
          this.setState({ transferencias })
        }
        toast.success('Cambios aplicados exitosamente')
        return this.setState({ loadingAcciones: false })
      })
      .catch(error => {
        this.setState({ loadingAcciones: false })
        return toast.error("Ocurrió un error y no se pudo completar esta operación")
      })

    }

    
    handleClose(){
      return this.setState({ show: false })
    }
    
    render(){
        const { user, fecha_consulta, selectmes, loadingTaza } = this.state

        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer
          position="bottom-right"
          theme="colored"
          />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Taza</h1>
        
      </div>
    {this.misDatos()}
      
      
    </main>
  </div>
</div>
    </div>
        )
    }
}

export default Taza