import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login';
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/Home';
import { PrivateRoute } from './components/PrivateRoute';
import Taza from './components/Taza';
import Configuracion from './components/Configuracion';

require('dotenv').config()

const App = (
  <Provider store={store}>
    <BrowserRouter>
    <Switch>
    
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/tazas" component={Taza} />
      <PrivateRoute exact path="/configuracion" component={Configuracion} />
      <Route path="/login" component={Login} />
    </Switch>
  </BrowserRouter>
  </Provider>
) 

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
