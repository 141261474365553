// import { authHeader } from '../_helpers';
import data from '../lib/backend/data'

export const userService = {
    login,
    logout
};

function login( username, password ) {
    return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/login_admin`, {
        method: 'POST',
        body: JSON.stringify({
            email: username.toLowerCase(),
            password: password
        }),
        headers: {
        'Content-Type':'application/json'
        }
    })
        .then(user => user.json())
        .then(user => {         
            if (user) {
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('dym_user', JSON.stringify(user));
                return user;
            }
            return false;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('dym_user');
}
