import React, { Component} from 'react'
import { connect } from 'react-redux'
import { userService } from '../../services/user.service'
import { Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'

class Home extends Component {
    constructor(){
        super()
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('dym_user')),
            loadinguser: true,
            loadinginsignias: true,
            insignias: [],
            reglasbanco: [],
            transferencias: [],
            fecha_consulta: this.formatDate(new Date()),
            show: false,
            showimg: "",
            loadingImagen: false,
            selectmes: this.formatMes(new Date()),
            ano: this.formatDateAno(new Date()),
            mesactual: "",
            palabra: "",
            filtro: ""
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFecha = this.handleChangeFecha.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.onChangeMes = this.onChangeMes.bind(this);
        this.handleChangeAno = this.handleChangeAno.bind(this)
    }

    actualizarMes(){
      const { selectmes, ano } = this.state
      return this.getTransferencias(`${ano}-${selectmes}`)
    }

    formatMes(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return month;
  }

    formatDateAno(date) {
      var d = new Date(date),
          year = d.getFullYear();
      return year;
  }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
        
    }

    async handleChangeFile(e){
      const { transferencias } = this.state
      const id = e.target.getAttribute('idtransfer')
      const pos = transferencias.findIndex(tra => tra._id === id)
      const data = new FormData() 

      data.append('imagen', e.target.files[0])
      this.setState({ loadingImagen: true })
      return fetch('https://aquicambias.com/server/dymsolutionsserver/public/archivo',{
          method: 'POST',
          body: data
      })
      .then(res => res.json())
      .then(async res => {
          
          if(res.link){
              toast.success('Imagen cargada exitosamente')
              transferencias[pos].imagenlista = res.link
              const guardar = await this.guardarImagenServidorActualizarTransferencia(id,res.link)
              if(!guardar){
                toast.error("Ocurrió un problema, intenta cargar tu foto nuevamente")
                return this.setState({ loadingImagen: false })
              }
              this.setState({ transferencias, loadingImagen: false })
          }

          return this.setState({ loadingImagen: false })
      })
      .catch(error => {
          console.log(error)
          this.setState({ loadingImagen: false })
          return toast.error('No se pudo subir la imagen')
      })

  }

  guardarImagenServidorActualizarTransferencia(id,link){


    this.setState({ loadingImagen: true })
    return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/subirFotoATransferencia',{
        method: 'POST',
        body: JSON.stringify({ 
          id,
          link
        })
    })
    .then(res => res.json())
    .then(res => true)
    .catch(error => false)
  }

    handleChangeFecha(e){
      const { name, value } = e.target
      this.setState({ [name]: value })
      return this.getTransferencias(value)
    }

    handleChangeAno(e){
      const { name, value } = e.target
      return this.setState({ ano: value })
     }


    onChangeMes(e){
      const { ano } = this.state
      const{ name, value } = e.target;

      const mesactual = `${ano}-${value}`
      this.setState({ mesactual, selectmes: value })
      this.getTransferencias(mesactual)
    }

    componentDidMount(){
      const { user } = this.state
      console.log(user)
      this.getBancos()

      const hoy = this.formatDate(new Date())
      this.getTransferencias(hoy)
    }


    buscarRegistros(){
      const { palabra } = this.state

      this.setState({ loadingTransferencias: true })
      return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/sistema_transferenciasBySearchWord?palabra=${palabra}`)
      .then(res => res.json())
      .then(res => {
          const transferencias = JSON.parse(res)
          console.log(transferencias)
          return this.setState({ transferencias, loadingTransferencias: false })
      })
      .catch(error => {
          toast.error('Error al consultar la información')
          this.setState({ loadingTransferencias: false })
          return false
      })
    }

    getTransferencias(fecha){
      this.setState({ loadingTransferencias: true })
      return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/transferenciasByFecha?fecha=${fecha}`)
      .then(res => res.json())
      .then(res => {
          const transferencias = JSON.parse(res)
          console.log(transferencias)
          return this.setState({ transferencias, loadingTransferencias: false })
      })
      .catch(error => {
          toast.error('Error al consultar la información')
          this.setState({ loadingTransferencias: false })
          return false
      })
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

    getBancos(){
      return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/bancos')
      .then(res => res.json())
      .then(res => {
          const reglasbanco = JSON.parse(res)
          console.log(reglasbanco)
          return this.setState({ reglasbanco })
      })
      .catch(error => {
          toast.error('Error al consultar la información')
          return false
      })
  }

    misDatos(){
      const { user, reglasbanco, transferencias } = this.state

      return <Row className="bienvenida">

      
        <Col xs={12} className="mt-3">
          <h5>{transferencias.length} Transferencias</h5>
        
            <Tabs defaultActiveKey="pendientes" id="uncontrolled-tab-example" className="mb-3">
                
                  <Tab eventKey="pendientes" title={`Pendientes ${transferencias.filter(tra => tra.status==='').length}`}>
                    <h3 className="text-secondary"><i className="far fa-clock "></i> Pendientes</h3>
                      {this.mostrarTransferencias(transferencias.filter(tra => tra.status==='' || tra.status === "0"))}
                  </Tab>

                  <Tab eventKey="encurso" title={`En curso ${transferencias.filter(tra => tra.status==='1').length}`}>
                    <h3 className="text-primary"><i className="fas fa-history "></i> En curso</h3>
                  {this.mostrarTransferencias(transferencias.filter(tra => tra.status==='1'))}
                  </Tab>

                  <Tab eventKey="finalizadas" title={`Finalizadas ${transferencias.filter(tra => tra.status==='2').length}`}>
                    <h3 className="text-success"><i className="fas fa-check-circle "></i> Finalizadas</h3>
                  {this.mostrarTransferencias(transferencias.filter(tra => tra.status==='2'))}
                  </Tab>

                  <Tab eventKey="canceladas" title={`Canceladas ${transferencias.filter(tra => tra.status==='10').length}`}>
                    <h3 className="text-danger"><i className="far fa-trash-alt"></i> Canceladas</h3>
                  {this.mostrarTransferencias(transferencias.filter(tra => tra.status==='10'))}
                  </Tab>

                </Tabs>
              
        
        </Col>

      </Row>

    }


    mostrarTransferencias(transferencias){
      const { reglasbanco, filtro } = this.state

      if(reglasbanco.length < 1) return false

      return <Tabs defaultActiveKey={reglasbanco[0].slug} id="uncontrolled-tab-example" className="mb-3">
                
      {reglasbanco.map((banco,ib) => {

        const lowercasedFilter = filtro.toLowerCase();
        const filtrados = transferencias.filter(tra => tra.banco === banco.slug).filter(item => {
              return Object.keys(item).some(key => {
                if(item[key]){
                  return item[key].toString().toLowerCase().includes(lowercasedFilter)
                }
              }
              )
        })

        return <Tab eventKey={banco.slug} title={`${banco.nombre} ${transferencias.filter(tra => tra.banco === banco.slug).length}`}>
        <Row>

        <Col xs={12}>
        <label className="form-control-label d-block">Filtrar resultados </label>
        <input className="form-control mb-3" placeholder="buscar por palabras clave" name="filtro" value={filtro} onChange={this.handleChange} />
        </Col>

        {
          filtrados.map((transfer,itra) => {

            return <Col md={6} className="mb-3">
              <Card key={`tab${itra}`}>
              <Card.Body>
                <Row>
                  <Col xs={4}>
                    <label className="form-control-label" ><b>Titular</b></label>
                    <p className="mb-0">{transfer.nombre_titular} {transfer.apellido_titular}</p>
                  </Col>
                  <Col xs={3}>
                    <label className="form-control-label" ><b>Cédula</b></label>
                    <p className="mb-0">{transfer.tipo_cedula} {transfer.cedula_titular}</p>
                  </Col>
                  <Col xs={5}>
                    <label className="form-control-label" ><b>Email</b></label>
                    <p className="mb-0">{transfer.email}</p>
                  </Col>
                  <Col xs={6}>
                    <label className="form-control-label" ><b>Tipo de cuenta</b></label>
                    <p className="mb-0">{transfer.tipo_cuenta}</p>
                  </Col>
                  <Col xs={6}>
                    <label className="form-control-label" ><b>Número de cuenta</b></label>
                    <p className="mb-0">{transfer.numcuenta}</p>
                  </Col>
                  <Col xs={3}>
                    <label className="form-control-label" ><b>Taza</b></label>
                    <p className="mb-0">{transfer.taza.ammount}</p>
                  </Col>
                  <Col xs={3}>
                    <label className="form-control-label" ><b>Cambiar</b></label>
                    <p className="mb-0">{transfer.cambiar}</p>
                  </Col>
                  <Col xs={3}>
                    <label className="form-control-label" ><b>Recibe</b></label>
                    <p className="mb-0">{(transfer.cambiar * transfer.taza.ammount).toFixed(2)}</p>
                  </Col>
                  <Col xs={3}>
                    <label className="form-control-label" ><b>Modificar estado</b></label>
                    {this.accionesEstado(transfer._id, transfer.status)}
                  </Col>
                  {this.cargaImagenIfEstado2(transfer.status, transfer._id)}
                  <Col xs={12}>
                  <button style={{ fontSize: 11 }} onClick={()=>this.abrirImagen(transfer.imagen)} className="btn btn-sm btn-outline-primary mr-3"><i className="far fa-image"></i> IMAGEN</button>
                  { transfer.imagenlista ? <button style={{ fontSize: 11 }} onClick={()=>this.abrirImagen(transfer.imagenlista)} className="btn btn-sm btn-outline-primary mr-3"><i className="far fa-image"></i> COMPROBANTE</button> :  false }
                  </Col>

                  <Col xs={12}>
                  <button className="btn btn-sm mt-3 btn-outline-danger" onClick={()=>this.modificarEstado("10",transfer._id)}>RECHAZAR SOLICITUD</button>
                  </Col>

              </Row>
            </Card.Body>
            </Card>
            </Col>

          })
        }
        
        </Row>
        </Tab>
      })}

      </Tabs>

    }

    cargaImagenIfEstado2(status,id){
      const { loadingImagen } = this.state
      if(!status) return false
      if(status === "1"){

        return <Col xs={12}>

          <label className="form-control-label">Carga tu imagen</label>
          <input type="file" className="form-control mb-3" idtransfer={id} onChange={this.handleChangeFile} />
          { loadingImagen ? <Spinner animation="border" /> : false }

        </Col>

      }

      return false
    }

    accionesEstado(id,status){
      const { loadingAcciones } = this.state
      if(loadingAcciones) return <Spinner animation="border" />
      if(status==="" || status === '10') return <button className="btn btn-sm btn-primary" onClick={()=>this.modificarEstado("1",id)}>EN CURSO</button>
      if(status==="1") return <button className="btn btn-sm btn-success" onClick={()=>this.modificarEstado("2",id)}>COMPLETADO</button>
      return <i className="fas fa-check-circle text-success"></i>
    }

    modificarEstado(estado,id){
      const { transferencias } = this.state
      const i = transferencias.findIndex(tra => tra._id === id)
      if(estado === "2"){
        if(i > -1){
          if( !transferencias[i].imagenlista ) return toast.error('Primero debes subir una imagen')
        }
      }


      this.setState({ loadingAcciones: true })
      return fetch(`https://aquicambias.com/server/dymsolutionsserver/public/api/marcar?id=${id}&status=${estado}`)
      .then(res => res.json())
      .then(res => {
        if(i > -1){
          transferencias[i].status = estado.toString()
          this.setState({ transferencias })
        }
        toast.success('Cambios aplicados exitosamente')
        return this.setState({ loadingAcciones: false })
      })
      .catch(error => {
        this.setState({ loadingAcciones: false })
        return toast.error("Ocurrió un error y no se pudo completar esta operación")
      })

    }

    abrirImagen(showimg){
      if(!showimg) return toast.error('No hay imagen')
      return this.setState({ show: true, showimg })
    }

    ifactive(status){
      switch(status){
        case '1':
          return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
      }
    }

    handleClose(){
      return this.setState({ show: false })
    }


    modal(){
      const { show, showimg } = this.state
      return <Modal show={show} onHide={()=>this.handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
      <Modal.Body>
        <img 
          style={{ width: '100%' }}
        src={showimg} />
      </Modal.Body>
    </Modal>
    }
    
    render(){
        const { user, fecha_consulta, selectmes, palabra, loadingTransferencias } = this.state

        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer
          position="bottom-right"
          theme="colored"
          />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Inicio</h1>
        
        {this.modal()}
      </div>

      <h3 style={{ fontWeight:100}}>Bienvenido {user ?  <b>{user.nombre}</b> : false } </h3>

      { loadingTransferencias ? <div><Spinner animation="border" /><h5>Cargando datos...</h5></div> : false }

      <label className="form-control-label" >Fecha de consulta</label>
      <input className="form-control" name="fecha_consulta" value={fecha_consulta} type="date" onChange={this.handleChangeFecha} />

      <div className="row">

      <div className="col-md-2 mt-3" >
  <label className="form-control-label">Buscar por año</label>
            <input style={{marginBottom: 15 }} className={`form-control`} type="number" onChange={this.handleChangeAno} value={this.state.ano} />
    </div>

      <div className="col-md-2 mt-3" >
  <label className="form-control-label">Buscar por mes </label>
<select className="form-control" defaultValue={selectmes} onChange={this.onChangeMes}>
  <option value="01">Enero</option>
  <option value="02">Febrero</option>
  <option value="03">Marzo</option>
  <option value="04">Abril</option>
  <option value="05">Mayo</option>
  <option value="06">Junio</option>
  <option value="07">Julio</option>
  <option value="08">Agosto</option>
  <option value="09">Septiembre</option>
  <option value="10">Octubre</option>
  <option value="11">Noviembre</option>
  <option value="12">Diciembre</option>
</select>
</div>

<div className="col-md-3 mt-3">
<label className="form-control-label">Click para refrescar </label>
  <button className="btn btn-sm btn-outline-success" onClick={()=>this.actualizarMes()}>ACTUALIZAR LISTADO DEL MES</button>
</div>

<div className="col-md-12"><hr /></div>

<div className="col-md-3 mt-3">
<label className="form-control-label d-block">Buscar por nombre o email </label>
<input className="form-control" name="palabra" value={palabra} onChange={this.handleChange} />
</div>

<div className="col-md-3 mt-3">
<label className="form-control-label d-block">Click para buscar </label>
  <button className="btn btn-sm btn-success" onClick={()=>this.buscarRegistros()}>BUSCAR</button>
</div>

      </div>
      <button className="btn btn-sm btn-outline-primary mt-3" onClick={()=>this.getTransferencias(fecha_consulta)}>ACTUALIZAR</button>
      {
        this.misDatos()
      }
    </main>
  </div>
</div>
    </div>
        )
    }
}

export default Home