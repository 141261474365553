import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import { Card } from 'react-bootstrap'

class Nav extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('rmkt_delpa_user')),
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    iconbytype(slug){
      switch(slug){
        case'evaluaciones':
          return "fas fa-users-cog";
        case'usuarios':
          return "fas fa-users";
          case'areas':
          return "fas fa-users";
        case'roles':
          return "fas fa-users";
        case'cargos':
          return "fas fa-users";
        case'matrices':
          return "fas fa-user-shield";
        default:
          break;
      }
    }

    render(){

        return(
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/" className="nav-link"><i className="fas fa-home"></i> Inicio</Link>
          </li>
          <li className="nav-item">
            <Link to="/tazas" className="nav-link"><i className="far fa-money-bill-alt"></i> Taza</Link>
          </li>
          <li className="nav-item">
            <Link to="/configuracion" className="nav-link"><i className="fas fa-cog"></i> Configuración</Link>
          </li>
        </ul>

        { /* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Saved reports</span>
          <a className="link-secondary" href="#" aria-label="Add a new report">
            <span data-feather="plus-circle"></span>
          </a>
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Current month
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Last quarter
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Social engagement
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Year-end sale
            </a>
          </li>
        </ul> */ }
      </div>
    </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Nav);