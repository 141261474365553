import React, { Component} from 'react'
import { Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

class Configuracion extends Component {
    constructor(){
        super()
        this.state = {
            user: JSON.parse(localStorage.getItem('dym_user')),
            configuracion: false,
            loadingConfiguracion: true
        }
        this.handleChange = this.handleChange.bind(this)
    }


    handleChange(e){
        const { value } = e.target
        return this.setState({ configuracion: value })
    }

    componentDidMount(){
        const { user } = this.state
        this.getConfiguracion()
    }

    getConfiguracion(){
        return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getConfiguracion')
        .then(res => res.json())
        .then(res => {
            return this.setState({ configuracion: res.valor, loadingConfiguracion: false })
        })
        .catch(error => {
            return this.setState({ loadingConfiguracion: false })
        })
    }


  guardarCambios(){
    const { configuracion } = this.state

    this.setState({ loadingConfiguracion: true })
      return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/modificarEstado?estado=${configuracion}`)
      .then(res => res.json())
      .then(async res => {
          toast.success('Cambios guardados exitosamente')
          return this.setState({ loadingConfiguracion: false })
      })
      .catch(error => {
          this.setState({ loadingConfiguracion: false })
          return toast.error('No se pudo completar la operación, recarga la página')
      })


  }

    misDatos(){
      const { configuracion, loadingConfiguracion, guardando } = this.state

      if(loadingConfiguracion) return <div><Spinner animation="border" /><h5>Cargando datos...</h5></div>

      return <Row className="bienvenida">

      
        <Col xs={12} className="mt-3">
          
        <Card className="mb-3">

        <Card.Body>
        <Row>
            <Col md={12}>
                <label className="form-control-label" >Estado del app</label>
                <select className="form-control" value={configuracion} onChange={this.handleChange}>
                    <option value="0">CERRADO</option>
                    <option value="1">ABIERTO</option>
                </select>
            </Col>

            <Col xs={12}>
            <button className="btn btn-success mt-3" onClick={()=>this.guardarCambios()} >GUARDAR CAMBIOS</button>
            </Col>

        </Row>
        </Card.Body>

        </Card>
        
        </Col>

      </Row>

    }

    
    modificarEstado(estado,id){
      const { transferencias } = this.state
      const i = transferencias.findIndex(tra => tra._id === id)
      if(estado === "2"){
        if(i > -1){
          if( !transferencias[i].imagenlista ) return toast.error('Primero debes subir una imagen')
        }
      }


      this.setState({ loadingAcciones: true })
      return fetch(`https://aquicambias.com/server/dymsolutionsserver/public/api/marcar?id=${id}&status=${estado}`)
      .then(res => res.json())
      .then(res => {
        if(i > -1){
          transferencias[i].status = estado.toString()
          this.setState({ transferencias })
        }
        toast.success('Cambios aplicados exitosamente')
        return this.setState({ loadingAcciones: false })
      })
      .catch(error => {
        this.setState({ loadingAcciones: false })
        return toast.error("Ocurrió un error y no se pudo completar esta operación")
      })

    }

    
    handleClose(){
      return this.setState({ show: false })
    }
    
    render(){
        const { user, fecha_consulta, selectmes, loadingConfiguracion } = this.state

        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer
          position="bottom-right"
          theme="colored"
          />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Taza</h1>
        
      </div>
    {this.misDatos()}
      
      
    </main>
  </div>
</div>
    </div>
        )
    }
}

export default Configuracion